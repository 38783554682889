<template>
   <modal :divisor="2.2" cancelText="Cancel" 
			:show="selectedDueButMissedProcedure !== null" 
			:footer="true" 
			:showCancel="false"
			:title="`Due but missed details: ${selectedDueButMissedProcedure?.name}`" 
			okText="Close" 
            @ok="selectedDueButMissedProcedure = null;"
            @close="selectedDueButMissedProcedure = null;">
      
      <table class="table table-striped table-responsive table-sm">
      <thead>
          <tr class="fw-bold fs-5 text-gray-800 border-bottom border-gray-200">                    
            <th>Patient</th>
            <th>Appointment Date</th>
            <th>Provider</th>
          </tr>
      </thead>
      <tbody>                
          <tr v-for="(appt, index) in selectedDueButMissedProcedure.appointments" :key="index">
              <td style="width: 25%;">{{ `${appt.patientInitials}`}} ({{ `${appt.patientId}`}})</td>                              
              <td style="width: 45%;">{{ `${appt.date}`}}</td>                              
              <td style="width: 30%;">{{ `${appt.providerName}`}}</td>
          </tr>                
      </tbody>      
    </table>
	</modal>

  <div class="loader" v-if="loading || loadingProviders"></div>
    <div class="row card">
    <div class="col-12">
      <div class="d-flex  py-2 align-items-center">
        <div class="d-flex align-items-center grey-bg py-2 px-7">
          <div class="symbol symbol-50px me-5">
            <i
              class="fas fa-calendar-alt fs-30-px mx-2"
              style="color: #009ef7;"
            ></i>
          </div>
          <div class="flex-grow-1 text-start">
            <span class="text-muted d-block fw-bold">Start</span>
            <input type="date" name="start" class="form-control border-none p-0" v-model="startDate" />
          </div>
        </div>
        <div class="d-flex align-items-center grey-bg py-2 px-7">
          <div class="symbol symbol-50px me-5">
            <i
              class="fas fa-calendar-alt fs-30-px mx-2"
              style="color: #009ef7;"
            ></i>
          </div>
          <div class="flex-grow-1">
            <span class="text-muted d-block fw-bold">End</span>
            <input type="date" name="end" class="form-control border-none p-0" v-model="endDate" />
          </div>
        </div>

        <Multiselect v-model="multiSelectObj.value" v-bind="multiSelectObj"/>
      <button
        class="btn btn-primary mx-10 cornered-radius"
         @click="getReportData"
        style="width: fit-content"
      >
        Generate
      </button>
      </div>
    </div>
  </div>

  <br />
  <div class="card mb-5" v-if="providerWorkReportSummary">
    <div class="card-header">
      <h3 class="card-title align-items-start flex-column">            
            <span class="card-label fw-bold text-dark">Summary</span>            
        </h3>
      
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col flex-grow-1 px-3">
          <span class="text-muted d-block fw-bold">Total revenue</span>
          <h5>{{ providerWorkReportSummary.totalRevenueCompleted }}$</h5>
        </div>
        <div class="col flex-grow-1 px-3">
          <span class="text-muted d-block fw-bold">Missed revenue</span>
          <h5>{{ providerWorkReportSummary.totalRevenuePlanned - providerWorkReportSummary.totalRevenueCompleted }}$</h5>
        </div>
        <div class="col flex-grow-1 px-3">
          <span class="text-muted d-block fw-bold">Planned vs completed (%)</span>
          <h5>{{ 100 - Math.round(providerWorkReportSummary.totalTreatmentsCompleted * 100.0/providerWorkReportSummary.totalTreatmentsPlanned) }}%</h5>
        </div>
        <div class="col flex-grow-1 px-3">
          <span class="text-muted d-block fw-bold">Patients affected by missed procedures</span>
          <h5>{{ providerWorkReportSummary.totalPatientsAffectedByMissedTreatments}}</h5>
        </div>
      </div>
      
      <div class="row mt-5" style="height:450px">
        <div class="col-6"><apexchart width="100%" height="100%" type="bar" :options="treatmentAcceptanceChartOptions" :series="plannedButMissedSeries">
            </apexchart></div>
        <div class="col-6">
          <apexchart width="100%" height="100%" type="bar" :options="dueButmissedProceduresChartOptions" @dataPointSelection="dueButMissedClicked" :series="dueButMissedSeries">
            </apexchart></div>
      </div>
      <div class="row mt-5" style="height:450px">
        <div class="col-6">
          <apexchart width="100%" height="100%" type="bar" :options="missedTreatmentsByReasonChartOptions" :series="missedTreatmentsByReasonSeries">
            </apexchart>
        </div>
      </div>

    </div>
  </div>
  


  <h3 class="">Provider breakdown</h3>
  <div class="card mb-5" v-for="(provider, i) in this.providerWorkReport" :key="i">
    <!--begin::Accordion-->
    <div
      class="accordion"
      :id="`kt_accordion_${i}`"
    >
      <div class="accordion-item">
        <h2 class="accordion-header" :id="`kt_accordion_1_header_${i}`">
          <button
            class="accordion-button fs-4 fw-bold"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#kt_accordion_1_body_${i}`"
            aria-expanded="false"
            :aria-controls="`kt_accordion_1_body_${i}`"
          >
            <div class="row provider-report align-items-center">
              <div class="col d-flex align-items-stretch">
                <div class="d-flex align-items-center grey-bg py-2">
                  <div class="flex-grow-1 px-3 d-flex align-items-center">
                    <div class="symbol symbol-50px me-2" >
                      <!-- <img
                          src="/media/avatars/150-26.jpg"
                          class="cornered-radius"
                          alt=""
                      /> -->
                      <Avatar
                        :initials="
                          `${provider.firstName.charAt(
                            0
                          )}${provider.lastName.charAt(0)}`
                        "
                      />
                    </div>
                    <div class="flex-grow-1">
                      <span class="text-muted d-block fw-bold">Name</span>
                      <a
                        href="#"
                        class="text-dark fw-bolder text-hover-primary fs-6"
                        >{{ `${provider.firstName} ${provider.lastName}` }}</a
                      >
                    </div>
                </div>
                  <div class="flex-grow-1 px-3">                    
                    <span class="text-muted d-block fw-bold">Completed</span>
                    <h5 :title="`${provider.totalCompleted}$ / ${provider.totalCompletedForPlannedAppointments}$`">{{ provider.successPercentage }}%</h5>
                  </div> 
                  <div class="flex-grow-1 px-3" title="Lost revenue from procedures that were planned but not executed on">                    
                    <span class="text-muted d-block fw-bold">Lost Revenue</span>
                    <h5>{{ Math.round(provider.totalPlanned - provider.totalCompletedForPlannedAppointments) }}$</h5>
                  </div>                  
                  <div class="flex-grow-1 px-3" v-if="provider.appointments.length > 0">                    
                    <inline-svg
                      style="width: 70%;"
                        src="/media/svg/files/pdf.svg"
                        @click="downloadCustom(i)"
                        title="Click to download PDF report."
                        class="cursor-pointer"
                      />                    
                  </div>  
                  <div class="flex-grow-1 px-3" v-if="provider.appointments.length > 0">                    
                    <i class="fa-solid fa-file-csv cursor-pointer" style="font-size: 42px" @click="downloadAsCSV(i)" title="Click to download CSV report."></i>              
                  </div>
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          :id="`kt_accordion_1_body_${i}`"
          class="accordion-collapse collapse"
          :aria-labelledby="`kt_accordion_1_header_${i}`"
          :data-bs-parent="`#kt_accordion_${i}`"
        >
          <div class="accordion-body">
            <ProductivityReportTable
            ref="prodTable"
              v-if="provider.appointments"
              :providerName="`${provider.firstName} ${provider.lastName}`"
              :appointments="provider.appointments"
              :clinicName="currentClinicName"
            />
          </div>
        </div>
      </div>
    </div>
    <!--end::Accordion-->
  </div>
</template>

<script>
import ProductivityReportTable from "@/components/Tables/ProductivityReportTable.vue";
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import * as bootstrap from "bootstrap";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import { format, sub } from "date-fns";
import Avatar from "@/components/Avatar.vue"
import {useToast} from "vue-toastification";
import {codes} from "@/Codes/Codes";
import { DateHelper } from "@/Utilities/DateHelper";
import Modal from "@/components/Modal.vue";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    Multiselect,
    ProductivityReportTable,
    Avatar,
    Modal,
  },
  data() {

    let {startingStartDate, startingEndDate} = DateHelper.getStartingIntervalDates();
    
    return {
      toast: useToast(),
      providerList: [],
      loading: false,
      loadingProviders: false,
      providerWorkReport: [],
      providerWorkReportSummary: null,
      startDate: startingStartDate.toISOString().split("T")[0],
			endDate: startingEndDate.toISOString().split("T")[0],
      multiSelectObj: {
        mode: "tags",
        value: [],
        searchable: true,
        closeOnSelect: false,
        options: [],
      },
      plannedButMissedSeries: [],
      dueButMissedSeries: [],
      missedTreatmentsByReasonSeries: [],
      treatmentAcceptanceChartOptions: {        
        title: {
          text: "Treatment Acceptance Rates",
          align: "center"
        },
        chart: {
            type: 'bar',    
            stacked: true,      
            stackType: '100%',  
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },        
        toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
            },
        },
        dataLabels: {
            enabled: true,       
                         
            //format for percentage
            formatter: this.treatmentAcceptanceLabelFormatter
        },        
        markers: {
            size: 5,
        },
        noData: {
            text: "No data available for selected range",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "black",
                fontSize: "14px",
                fontFamily: undefined,
            },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        legend: {
            show: false            
        }  ,
        tooltip: {
          y: {
            formatter: function (val, opts) {
              let totalCount  = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].totalCount;
              let partCount  = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].partCount;
              return val.toFixed(0) +  "%" + ` (${partCount}/${totalCount})`;              
            }
          }
        }        
      },
      missedTreatmentsByReasonChartOptions: {
        colors: ['#4363d8'],
        chart: {
            type: 'bar',            
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },     
        title: {
          text: "Top reasons for missed procedures",
          align: "center"
        },   
        toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
            },
        },
        dataLabels: {
            enabled: true,                    
        },        
        markers: {
            size: 5,
        },
        noData: {
            text: ['Start tracking missed procedures', 'to populate this chart'],
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "black",
                fontSize: "14px",
                fontFamily: undefined,
            },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        legend: {
            show: false            
        }          
      },
      dueButmissedProceduresChartOptions: null,
      selectedDueButMissedProcedure: null
    };
  },

  async beforeMount() {},

  async mounted() {
    this.getProviders();
  },
  methods: {
    treatmentAcceptanceLabelFormatter(val, opts) {
      let totalCount  = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].totalCount;
      let partCount  = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].partCount;     
      if (val < 4)
        return ""; //doesn't fit  if its that small
      else         
        return val.toFixed(0) +  "%" + (val < 20 ? "" :` (${partCount}/${totalCount})`);
    },
    dueButMissedLabelFormatter(val, opts) {
      let completedCount = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].completedCount;
      let missedCount = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].missedCount;              
        return val.toFixed(0) +  "%" + (val < 20 ? "" :` (${missedCount}/${completedCount + missedCount})`);
    },
    downloadCustom(i){
      this.$refs.prodTable[i].downloadCustom();
    },
    downloadAsCSV(i){
      this.$refs.prodTable[i].downloadAsCSV();
    },
    dateFormat(date, formatString) {
      return format(date, formatString);
    },
    async getProviders() {
      this.loadingProviders = true;
      try{
        let res = await ApiService.query(`Provider/Details?activeOnly=true`);
        // console.log(res.data);
        if (res) {
          let tempProviderList = res.data;
          tempProviderList.forEach((element) => {
            if (element.isHygienist  && element.excludeFromCalendar == false) {
              this.providerList.push({
                value: element.provider_id,
                label: element.first_name + " " + element.last_name,
              });
              this.multiSelectObj.value.push(element.provider_id)
            }
          });

          this.multiSelectObj.options = this.providerList;

          // console.log("providerList", this.providerList);
        }
        if(res.status !== 200) {
          this.toast.error(codes.error);
        }
        this.getReportData();
      }catch (e){        
        this.toast.error(codes.error);
        throw e;
      }finally{
                this.loadingProviders = false;
            }
    },
    async getReportData() {
      this.loading = true;
      try{
        let ReportModel = {
          startDate: this.startDate,
          endDate: this.endDate,
          providerIds: this.multiSelectObj.value,
        };

        let res = await ApiService.post(
            "Report/GetProviderWorkReport",
            ReportModel
        );

        const procRules = await ApiService.get(`/ProcedureRules`);
        const procedureRuletypes = Object.assign({}, ...procRules.data.map((p) => ({[p.procedureRuleTypeId]: p})));      
        console.log("procedureRuletypes", procedureRuletypes);
        console.log("res", res);
        if (res) {          
          for(let provider of res.data.providerWorkReports){
            for(let appointment of provider.appointments){
              let firstItems = appointment.procedures.filter(p => procedureRuletypes[p.procedureRuleTypeId].scheduleOrderBeginning !== null)
              DateHelper.sortArrayByFunction(firstItems, (item => {return procedureRuletypes[item.procedureRuleTypeId].scheduleOrderBeginning}), "asc");
              let lastItems = appointment.procedures.filter(p => procedureRuletypes[p.procedureRuleTypeId].scheduleOrderEnd !== null)
              DateHelper.sortArrayByFunction(lastItems, (item => {return procedureRuletypes[item.procedureRuleTypeId].scheduleOrderEnd}), "asc");
              let middleItems = appointment.procedures.filter(p => procedureRuletypes[p.procedureRuleTypeId].scheduleOrderEnd === null && procedureRuletypes[p.procedureRuleTypeId].scheduleOrderBeginning === null)
              appointment.procedures = [...firstItems,...middleItems,...lastItems];
            }
          } 
          this.providerWorkReport = res.data.providerWorkReports;         
          this.providerWorkReportSummary = res.data.providerWorkReportSummary;
          let asArray = Object.entries(this.providerWorkReportSummary.plannedTreatmentSuccessRateByTreatment);
          
          asArray = asArray.filter(i => i[1].percentage < 100);
          DateHelper.sortArrayByFunction(asArray, (item => {return item[1].percentage}), "asc");
          console.log("asArray", asArray);
          //let series = asArray.map(i => i[1]);


          let series = [
          {
              name: "Completed (%)",
              color: '#4EA72E',
              data: asArray.map(i => ({x: i[0], y: i[1].percentage, 
                totalCount: i[1].completedCount +  i[1].missedCount, partCount: i[1].completedCount}))
            },
          ];

          /**
           * {
              name: "Patient Declined (%)",
              data: asArray.map(i => ({x: i[0], y: i[1].patientDeclinedPercentage , totalCount: i[1].completedCount +  i[1].missedCount, 
                partCount: i[1].patientDeclinedCount}))
            },  
          {
              name: "Missed for other reasons (%)",
              data: asArray.map(i => ({x: i[0], y: (100 - i[1].percentage) - i[1].patientDeclinedPercentage, 
                totalCount: i[1].completedCount +  i[1].missedCount, partCount: i[1].missedCount - i[1].patientDeclinedCount}))
            }            
           * 
           */
          //get distinct series from backend
          //'#C04F15', '#7F7F7F', '#D9D9D9'],
          //,"TreatmentPlanChanged","DoctorNotAvailable","Other","Unspecified" ];
          let seriesNamesAndColors = [{ name: "PatientDeclined", color: '#E97132'} , { name: "RanOutOfTime", color: '#C04F15'}, 
          { name: "TreatmentPlanChanged", color: '#8B3E14'}, { name: "DoctorNotAvailable", color: '#5A2710'}, { name: "Other", color: '#7F7F7F'}, { name: "Unspecified", color: '#D9D9D9'}];
          console.log(seriesNamesAndColors, "seriesNamesAndColors");
          for(let seriesNameAndColor of seriesNamesAndColors){
            series.push({
              name: seriesNameAndColor.name,
              color: seriesNameAndColor.color,
              data: asArray.map(i => ({x: i[0], y: i[1].missedReasonPercentage[seriesNameAndColor.name] ?? 0, totalCount: i[1].completedCount +  i[1].missedCount, 
                partCount: i[1].missedReasonCount[seriesNameAndColor.name] ?? 0}))
            });
          }



          console.log("series", series);
          this.plannedButMissedSeries = series;

          let dueAsArray = Object.entries(this.providerWorkReportSummary.dueTreatmentSuccessRateByTreatment);
          console.log("dueAsArray", dueAsArray);
          dueAsArray = dueAsArray.filter(i => i[1].percentage < 100);
          DateHelper.sortArrayByFunction(dueAsArray, (item => {return item[1].percentage}), "asc");
          let dueSeries = [ {
              name: "Due but missed (%)",
              data: dueAsArray.map(i => ({x: i[0], y: (100 - i[1].percentage), completedCount: i[1].completedCount, missedCount: i[1].missedCount}))
            } , 
          ];
          console.log("dueSeries", dueSeries);
          this.dueButMissedSeries = dueSeries;

          let missedTreatmentsByReasonSeriesAsArray = Object.entries(this.providerWorkReportSummary.topReasonsForMissedTreatments);
          DateHelper.sortArrayByFunction(missedTreatmentsByReasonSeriesAsArray, (item => {return item[1]}), "desc");
          this.missedTreatmentsByReasonSeries = [{ data: missedTreatmentsByReasonSeriesAsArray.map(i => ({x: i[0], y: i[1]}))}];
          console.log("missedTreatmentsByReasonSeries", this.missedTreatmentsByReasonSeries);
          //this.summaryChartOptions.categories = asArray.map(i => i[0]);
          this.dueButmissedProceduresChartOptions = JSON.parse(JSON.stringify(this.treatmentAcceptanceChartOptions)); 
          this.dueButmissedProceduresChartOptions.title.text = "Due but Missed Procedures";
          this.dueButmissedProceduresChartOptions.chart.stacked = false;
          this.dueButmissedProceduresChartOptions.colors = ['#4363d8'];

          this.dueButmissedProceduresChartOptions.dataLabels.formatter = this.dueButMissedLabelFormatter;          
        }
        if (res.status !== 200) {
          this.toast.error(codes.error);
        }
      }catch (e) {        
        this.toast.error(codes.error);
        throw e;
      }finally{
                this.loading = false;
            }
    },
    dueButMissedClicked(event, chartContext, config) {
      //what series was clicked
      console.log("x clicked", config.w.config.series[0].data[config.dataPointIndex].x)
      let procedureName = config.w.config.series[0].data[config.dataPointIndex].x;

      this.selectedDueButMissedProcedure = {
        name: procedureName,
        appointments: this.providerWorkReport
          .flatMap(r => r.appointments.map(appointment => ({ ...appointment, providerId: r.providerId })))
          .filter(d => d.dueButNotDoneProcedures.filter(dp => dp.name.toLowerCase().includes(procedureName.toLowerCase())).length > 0)
          .map(d => ({ 
            patientId:d.patientId, 
            patientInitials:d.patientInitials, 
            date: d.startDate.split("T")[0] + " " + d.startDate.split("T")[1], 
            providerName: `${d.providerId}`}))
      };
    }

  },
  setup() {
    setCurrentPageTitle("Productivity Report");
    const checked = ref(false);
    const store = useStore();
    const initialProvider = store.getters.currentUser;
    const currentClinicName = initialProvider.currentClinic.name;
    const instanceInfo = store.getters.currentInstanceInfo;
    return {
      checked,
      initialProvider,
      currentClinicName,
      instanceInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../../../node_modules/@vueform/multiselect/themes/default.css";

:deep( .multiselect-tag) {
  background: var(--ms-tag-bg, #0095e8);
}

:deep( .multiselect-clear:hover .multiselect-clear-icon) {
  background: var(--ms-clear-color, #999);
}

.chargeHeader {
  text-align: right;
  padding-right: 30px;
}

.chargeLabel {
  text-align: right;
  padding-right: 30px;
  width: 100%;
}

.totalLabel {
  text-align: right;
  padding-right: 30px;
  border-top: 2px solid black;
  padding-top: 5px;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    position: relative;
    left: -25%;
}
.accordion-button:not(.collapsed) {
  color: #FFF  !important;
  background-color: #FFF !important;
}

.accordion-button:hover  {
  background-color: #FFF !important;
}

</style>
